import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Hr, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				PeakFitness
			</title>
			<meta name={"description"} content={"Наш заклад може похвалитися широким вибором обладнання, різноманітними заняттями та персоналізованими фітнес-програмами, які створені для досягнення ваших цілей щодо фізичного та психічного здоров’я."} />
			<meta property={"og:title"} content={"PeakFitness"} />
			<meta property={"og:description"} content={"Наш заклад може похвалитися широким вибором обладнання, різноманітними заняттями та персоналізованими фітнес-програмами, які створені для досягнення ваших цілей щодо фізичного та психічного здоров’я."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/666c07b88d4a0c0020306e7c/images/1-3.jpg?v=2024-06-14T11:56:03.728Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/666c07b88d4a0c0020306e7c/images/1-3.jpg?v=2024-06-14T11:56:03.728Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/666c07b88d4a0c0020306e7c/images/1-3.jpg?v=2024-06-14T11:56:03.728Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/666c07b88d4a0c0020306e7c/images/1-3.jpg?v=2024-06-14T11:56:03.728Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/666c07b88d4a0c0020306e7c/images/1-3.jpg?v=2024-06-14T11:56:03.728Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/666c07b88d4a0c0020306e7c/images/1-3.jpg?v=2024-06-14T11:56:03.728Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/666c07b88d4a0c0020306e7c/images/1-3.jpg?v=2024-06-14T11:56:03.728Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="link1">
				МЕНЮ
			</Override>
		</Components.Header>
		<Section padding="60px 0 60px 0" md-padding="30px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				padding="0px 90px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				width="50%"
				lg-padding="0px 28px 0px 0px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				align-self="center"
			>
				<Text
					font="--headline2"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					margin="0px 0px 32px 0px"
					color="--light"
				>
					Розширення можливостей кожного вашого руху
				</Text>
				<Text
					color="--light"
					font="--base"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 16px 0px"
					margin="0px 0px 0px 0px"
				>
					У PeakFitness ми надаємо широкий спектр послуг, розроблених для покращення вашої фітнес-мандрівки. Від найсучаснішого обладнання до спеціалізованих занять, кожна пропозиція розроблена, щоб допомогти вам досягти ваших особистих цілей щодо здоров’я та фізичної форми у сприятливому та динамічному середовищі.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					src="https://uploads.quarkly.io/666c07b88d4a0c0020306e7c/images/1-2.jpg?v=2024-06-14T11:56:03.684Z"
					width="100%"
					max-height="100%"
					object-fit="cover"
					lg-min-height="300px"
					md-max-height="277px"
					md-min-height="16px"
					srcSet="https://smartuploads.quarkly.io/666c07b88d4a0c0020306e7c/images/1-2.jpg?v=2024-06-14T11%3A56%3A03.684Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/666c07b88d4a0c0020306e7c/images/1-2.jpg?v=2024-06-14T11%3A56%3A03.684Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/666c07b88d4a0c0020306e7c/images/1-2.jpg?v=2024-06-14T11%3A56%3A03.684Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/666c07b88d4a0c0020306e7c/images/1-2.jpg?v=2024-06-14T11%3A56%3A03.684Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/666c07b88d4a0c0020306e7c/images/1-2.jpg?v=2024-06-14T11%3A56%3A03.684Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/666c07b88d4a0c0020306e7c/images/1-2.jpg?v=2024-06-14T11%3A56%3A03.684Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/666c07b88d4a0c0020306e7c/images/1-2.jpg?v=2024-06-14T11%3A56%3A03.684Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" margin="0px 0px 0px 0px" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" margin="0px auto 0px auto" />
			<Box
				display="flex"
				flex-direction="column"
				margin="0px 0px 0px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-width="100%"
			>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Наші послуги
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Індивідуальні плани фітнесу:  Спеціально розроблені для ваших цілей, незалежно від того, чи хочете ви схуднути, набрати м’язи або покращити загальну фізичну форму.{"\n"}
							<br />
							<br />
							Індивідуальний коучинг: Працюйте безпосередньо з нашими сертифікованими тренерами для персоналізованого керівництва та підтримки.{"\n"}
							<br />
							<br />
							{"\n"}Групові заняття
Йога та пілатес: Підвищте гнучкість, силу та ясність розуму за допомогою наших занять розуму та тіла.{"\n"}
							<br />
							<br />
							Заняття спінінгом і кардіотренуванням: Покращуйте здоров’я серцево-судинної системи за допомогою наших високоенергетичних групових занять.{"\n"}
							<br />
							<br />
							Аеробіка: Виконуйте веселі та енергійні процедури, щоб покращити свою фізичну форму в груповій обстановці.{"\n"}
							<br />
							<br />
							Навчальні табори: Випробуйте себе на наших заняттях на відкритому повітрі, спрямованих на підвищення сили та витривалості.{"\n"}
							<br />
							<br />
							{"\n"}Сила та кондиція
 Тренування з обтяженнями:  Отримайте доступ до нашого широкого асортименту вільних обтяжень і тренажерів, розроблених для всіх рівнів.{"\n"}
							<br />
							<br />
							Функціональне тренування: Покращуйте свою витривалість і силу за допомогою високоінтенсивних функціональних рухів.{"\n"}
							<br />
							<br />
							Кругове тренування: Спробуйте високоінтенсивне кругове тренування, яке підвищує витривалість і швидкість метаболізму.{"\n"}
							<br />
							<br />
							{"\n"}Покращені оздоровчі послуги
Крім традиційних пропозицій тренажерних залів, PeakFitness зосереджується на цілісному оздоровленні, яке доповнює вашу фізичну підготовку.
							<br />
							<br />
							{"\n\n"}Консультації з питань харчування
Дієтичні плани: Отримайте індивідуальні дієтичні вказівки, щоб покращити свої результати у формі.{"\n"}
							<br />
							<br />
							Семінари з харчування: Дізнайтеся, як підтримувати здорову дієту з нашими експертами-дієтологами.{"\n"}
							<br />
							<br />
							{"\n"}Психічне здоров’я
 Семінари з управління стресом:  Відкрийте для себе методи боротьби зі стресом і покращення психічного здоров’я.{"\n"}
							<br />
							<br />
							Сеанси медитації під керівництвом: Приєднуйтеся до наших занять, щоб розвивати уважність і розслаблення.{"\n"}
							<br />
							<br />
							Оздоровчі ретрити: Беріть участь у наших ексклюзивних ретритах, спрямованих на всебічне оздоровлення та омолодження.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 0px 0px" />
			</Box>
		</Section>
		<Section
			padding="140px 0 140px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/666c07b88d4a0c0020306e7c/images/1-1.jpg?v=2024-06-14T11:56:03.682Z) center center/cover no-repeat"
			min-height="40vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline3"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Дізнайтеся більше з нами
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					Це лише короткий огляд того, що може запропонувати PeakFitness. Ми запрошуємо вас відвідати нас і ознайомитися з повним спектром наших послуг, розроблених для того, щоб задовольнити всі аспекти вашого здоров’я та фізичної форми. Наш доброзичливий персонал завжди готовий надати додаткову інформацію та допомогти вам вибрати найкращі варіанти для вашої подорожі фітнесом.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Контакти
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});